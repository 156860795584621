// src/components/Map.js
import React from 'react';

const Map = () => {
  return (
    <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.546856223936!2d73.75985475114392!3d18.54936863769323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf0026e176a3%3A0xe74557643ad3b52e!2ssus%20town!5e0!3m2!1sen!2smy!4v1708250518819!5m2!1sen!2smy"
    width="100%"
    height="400"
    frameBorder="0"
    scrolling="no"
    marginHeight="0"
    marginWidth="0"
    style={{ filter: 'invert(90%)' }}
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
  ></iframe>
  );
};

export default Map;
