// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="copyright">Copyright &copy; s U s t 0 w n 2024</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
