// src/components/MainContent.js
import React from 'react';
import Map from './Map'

const Main = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="box">
          <div className="col-lg-12 text-center">
            <h2 className="brand-before">
              <small>Welcome to</small>
            </h2>
            <h1 className="brand-name">s U s t 0 w n</h1>
            <hr className="tagline-divider" />
            <h2>
              <small>
                <strong>TheGamerACE309</strong>
              </small>
            </h2>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="box">
          <div className="col-lg-12">
            <hr />
            <h2 className="intro-text text-center">
              sussy
            </h2>
            <hr />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="box">
          <div className="col-lg-12">
            <hr />
            <h2 className="intro-text text-center">Contact
              <strong> s U s t 0 w n</strong>
            </h2>
            <hr />
          </div>
          <div className="col-lg-12">
            <Map />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
